import { useEffect } from 'react';
import config from "../config.json"
import { useDispatch } from 'react-redux';
import { loadProvider, 
         loadNetwork, 
         loadAccount, 
         loadToken, 
         loadTokens, 
         loadExchange,
         LoadBalance,
         subscribesToEvents,
         loadAllOrders
        } from '../store/interactions';

import NavBar from './Navbar';
import Markets from "./Markets"
import Balance from './Balance';
import Order from './Order';
import OrderBook from './OrderBook';
import PriceChart from './PriceChart';
import Trades from './Trades';
import Transactions from "./Transactions"
import Alert from './Alert';

function App() {

  const dispatch = useDispatch()

  const loadBCData = async() =>{

    const provider = loadProvider(dispatch)
    const chainId = await loadNetwork(provider, dispatch)
    let account 
    
    window.ethereum.on('chainChanged', async() => {
      window.location.reload()
    })

    window.ethereum.on('accountsChanged', async() => {
      account = await loadAccount(provider, dispatch)
    })
    
    await loadTokens(provider, [ config[chainId]["xCzsr"]["address"], config[chainId]["mEth"]["address"] ], dispatch)
    const exchange = await loadExchange(provider, config[chainId]["exchange"]["address"], dispatch)
    // console.log("echcnage loaded", exchange)
    loadAllOrders(provider, exchange, dispatch)
    subscribesToEvents(exchange, dispatch)

  }
  
  useEffect(()=>{
  
    loadBCData()

  })

  return (
    <div>

    <NavBar></NavBar>

      <main className='exchange grid'>
        <section className='exchange__section--left grid'>
          {/* {Market} */}
          <Markets></Markets>

          {/* Balance */}
          <Balance></Balance>

          {/* Order */}
          <Order></Order>
        </section>


        <section className='exchange__section--right grid'>
            {/* Price Chart */}
            <PriceChart></PriceChart>
            {/* Transaction */}
            <Transactions></Transactions>
            {/* Trade */}
            <Trades></Trades>
            {/* OrderBook */}

            <OrderBook/>

            </section> 
        
      </main>

      <Alert></Alert>
    </div>
  );
}

export default App;
