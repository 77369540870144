import { useDispatch, useSelector } from "react-redux";
import { myFilledOrdersSelector, myOpenOrdersSelector } from "../store/selector";

import sort from "../assets/sort.svg"
import Banner from "./Banner";
import { useRef , useState } from "react";
import { cancelOrder } from "../store/interactions";
import { exchange } from "../store/reducers";


const Transactions = () => {

  const symbols = useSelector(state => state.tokens.symbols)
  const provider = useSelector(state => state.provider.connection)
  const exchange = useSelector(state => state.exchange.contract)

  const myOpenOrder = useSelector(myOpenOrdersSelector)
  const myFilledOrders = useSelector(myFilledOrdersSelector)
  const [ showMyOrder, setShowmyOrders] = useState(true)
  const tradeRef = useRef(null)
  const orderRef = useRef(null)

  const dispatch = useDispatch()
  

  const tabHandler = (e) => {

    if(e.target.className !== orderRef.current.className){
      e.target.className = "tab tab--active"
      orderRef.current.className = 'tab'
      setShowmyOrders(false)
    }
    else {
      e.target.className = "tab tab--active"
      tradeRef.current.className = "tab"
      setShowmyOrders(true)


    }
  
    
  }

  const cancelHandler = (order) => {
    console.log("cancel Order")
    console.log(exchange)

    cancelOrder(provider, exchange[0], order, dispatch)


  }

  const printStuff = (order) => {
    console.log("My new new Order")
    console.log(order)

  }
    return (
      <div className="component exchange__transactions">
        
        {showMyOrder ? 
        (
          <div>
            <div className='component__header flex-between'>
              <h2>My Orders</h2>
    
              <div className='tabs'>
                <button onClick={tabHandler} ref={orderRef} className='tab tab--active'>Orders</button>
                <button onClick={tabHandler} ref={tradeRef} className='tab'>Trades</button>
              </div>
            </div>
              {!myOpenOrder || myOpenOrder.length === 0 ? 
              (<Banner text={"No Open Orders"}/>)
              :
              (
              <table>
                <thead>
                  <tr>
                    <th>{symbols && symbols[0]}<img src={sort} alt="Sort"/></th>
                    <th> {symbols && symbols[0]}/{ symbols && symbols[1]}<img src={sort} alt="Sort"/></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {myOpenOrder && myOpenOrder.map((order, index) => {
                    return(
                    <tr key={index}>
                      <td style={{color : `${order.orderTypeClass}`}}>{order.token0Amount}</td>
                      <td>{order.tokenPrice}</td>
                      <td><button className="button--sm" onClick={() => cancelHandler(order)}>Cancel</button></td>
                    </tr>
                    )

                  })}
                  
                </tbody>
              </table>
              )
              }
    
          </div>

        )
        :
        (
          <div>
            <div className='component__header flex-between'>
              <h2>My Transactions</h2>
              <div className='tabs'>
                <button onClick={tabHandler} ref={orderRef} className='tab tab--active'>Orders</button>
                <button onClick={tabHandler} ref={tradeRef} className='tab'>Trades</button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Time <img src={sort} alt={"Sort"}/></th>
                  <th>{symbols && symbols[0]}<img src={sort} alt="Sort"/></th>
                  <th> {symbols && symbols[0]}/{ symbols && symbols[1]}<img src={sort} alt="Sort"/></th>
                </tr>
              </thead>
              <tbody>

                { myFilledOrders ? ( myFilledOrders.map((order, index) => {
                  //  console.log(myFilledOrders)
                  return(
                  <tr key={index}>
                    <td>{order.formattedTimeStamp}</td>
                    <td style={{color: `${order.orderClass}`}}>{order.orderSign}{order.token0Amount}</td>
                    <td>order.</td>
                  </tr>)
                }))
                :
                console.log("nothing in fill order")
              }
                              
              </tbody>
            </table>
          </div>
        )

        }
  
      </div>
    )
  }
  
  export default Transactions;