import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import { provider, tokens , exchange} from "./reducers"

const reducer = combineReducers({
    provider,
    tokens, 
    exchange
})

const intialState = {}

const middleware = [thunk]

const store = createStore(reducer, intialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store


// using redux https://redux.js.org/introduction/getting-started
// using react-redux https://react-redux.js.org/introduction/getting-started
