import dapp from '../assets/dapp.svg'
import eth from '../assets/eth.svg'
import { useDispatch, useSelector } from 'react-redux';

import { LoadBalance, transferTokens} from '../store/interactions';
import { useEffect , useState , useRef} from 'react';
import { exchange, provider, tokens } from '../store/reducers';


const Balance = () =>{

    const dispatch = useDispatch()

    const  [isDeposit, setIsDeposit] = useState(true)
    const [token1TransferAmount, settoken1TransferAmount] = useState(0)
    const [token2TransferAmount, settoken2TransferAmount] = useState(0)

    const provider           =    useSelector(state => state.provider.connection)
    const account            =    useSelector(state => state.provider.account)
    const exchange           =    useSelector(state => state.exchange.contract)
    const exchangeBalances   =    useSelector(state => state.exchange.balances)
    const exchangeInProgress =    useSelector(state => state.exchange.transferInProgress)
    const tokens             =    useSelector(state => state.tokens.contracts)
    const symbols            =    useSelector(state => state.tokens.symbols)
    const tokensBalances     =    useSelector(state => state.tokens.balances)

    useEffect(() => {
        if(exchange && tokens[0] && tokens[1] && account ){
            LoadBalance(exchange, tokens, account, dispatch)

        }
    }, [exchange, tokens, account, exchangeInProgress])

    const withdrawHandler = (e, token) =>{

        e.preventDefault() // prevents from refreshing the page

        if(token.address == tokens[0].address){

            transferTokens(provider, exchange[0], "Withdraw", token, token1TransferAmount, dispatch)
            settoken1TransferAmount(0)
        }
        else if(token.address == tokens[1].address){

            transferTokens(provider, exchange[0], "Withdraw", token, token2TransferAmount, dispatch)
            settoken2TransferAmount(0)
        }


    }

    const depositHandler = (e, token) => {

        e.preventDefault() // prevents from refreshing the page

        if(token.address == tokens[0].address){

            transferTokens(provider, exchange[0], "Deposit", token, token1TransferAmount, dispatch)
            settoken1TransferAmount(0)
        }
        else if(token.address == tokens[1].address){

            transferTokens(provider, exchange[0], "Deposit", token, token2TransferAmount, dispatch)
            settoken2TransferAmount(0)
        }

    }

    const amountHandler = (e, token) => {

        if(token.address == tokens[0].address){
            settoken1TransferAmount(e.target.value)
        }
        else if(token.address == tokens[1].address){
            settoken2TransferAmount(e.target.value)
        }
        else {
            console.log("Unknown token")
        }


    }

    const depositRef = useRef(null)
    const withdrawRef = useRef(null)

    const tabHandler = (e) =>{

        if(e.target.className != depositRef.current.className){

            e.target.className = "tab tab--active"
            depositRef.current.className = "tab"
            setIsDeposit(false)
        }
        else {
            e.target.className = "tab tab--active"
            withdrawRef.current.className = "tab"
            setIsDeposit(true)

        }
    }

    return(
        <div className='component exchange__transfers'>
            <div className='component__header flex-between'>
                <h2>Balance</h2>
                <div className='tabs'>
                    <button onClick={tabHandler} ref={depositRef} className='tab tab--active'>Deposit</button>
                    <button onClick={tabHandler} ref={withdrawRef} className='tab'>Withdraw</button>
                </div>
            </div>

            <div className='exchange__transfer--form'>
                <div className='flex-between'>
                    <p> 
                        <small>Token</small>
                        <br/>
                        <img src={dapp} alt="Token Logo"/> 
                        {symbols && symbols[0]}
                    </p>
                    <p><small>Wallet</small><br/> {tokensBalances && tokensBalances[0]} </p>
                    <p><small>Exchange</small><br/> {exchangeBalances && exchangeBalances[0]} </p>
                </div>

                <form onSubmit={ isDeposit ? (e) => depositHandler(e, tokens[0]): (e) => withdrawHandler(e, tokens[0])}>
                    <label htmlFor='token0'>{symbols && symbols[0]} Amount</label>
                    <input 
                        type='text' id='token0' placeholder='0.0000' 
                        value={token1TransferAmount === 0 ? '' : token1TransferAmount} 
                        onChange={(e) => amountHandler(e, tokens[0])}/>
                    <button className='button' type='submit'>
                        { isDeposit ? (<span>Deposit</span>) : (<span>Withdraw</span>)}
                        
                    </button>
                </form>


            </div>
            <hr/>

            {/* Deposit/Withdraw Component 2 (mETH) */}

            <div className='exchange__transfers--form'>
                <div className='flex-between'>
                    <p> 
                        <small>Token</small>
                        <br/>
                        <img src={eth} alt="Token Logo"/> 
                        {symbols && symbols[1]}
                    </p>
                    <p><small>Wallet</small><br/> {tokensBalances && tokensBalances[1]} </p>
                    <p><small>Exchange</small><br/> {exchangeBalances && exchangeBalances[1]} </p>

                </div>

                <form onSubmit={ isDeposit ? (e) => depositHandler(e, tokens[1]) : (e) => withdrawHandler(e, tokens[1])} >
                    
                <label htmlFor="token1">{symbols && symbols[1]} Amount</label>
                <input 
                    type='text' id='token0' placeholder='0.0000' 
                    value={token2TransferAmount === 0 ? '' : token2TransferAmount} 
                    onChange={(e) => amountHandler(e, tokens[1])}/>

                <button className='button' type='submit'>
                    { isDeposit ? (<span>Deposit</span>) : (<span>Withdraw</span>)}
                </button>
                </form>
            </div>

            <hr />
        </div>
    )
} 
export default Balance;

