import { useState } from "react";
import { useRef } from "react";
import { makeBuyOrder, makeSellOrder } from "../store/interactions";
import { useSelector, useDispatch} from "react-redux";


const Order = () =>{
    const [amount, setAmount ] = useState(0)
    const [price, setPrice ] = useState(0)
    const [isBuy, setIsBuy] = useState(true)
    
    const buyRef = useRef(null)
    const sellRef = useRef(null)

    const provider           = useSelector(state => state.provider.connection)
    const dispatch           = useDispatch()    
    const exchange           = useSelector(state => state.exchange.contract)

    const tokens             = useSelector(state => state.tokens.contracts)




    const tabHandler=(e) =>{ 

        if (e.target.className !== buyRef.current.className){
            e.target.className = 'tab tab--active'
            buyRef.current.className = "tab"
            setIsBuy(false)

        }
        else {
            e.target.className = 'tab tab--active'
            sellRef.current.className = "tab"
            setIsBuy(true)

        }

    }

    const buyHandler=(e) => {
        
        e.preventDefault()

        makeBuyOrder(provider, exchange[0], tokens, {amount, price} , dispatch)
        setAmount(0)
        setPrice(0)
        console.log("Buy")

    }
    const sellHandler=(e) => {
        e.preventDefault()
        makeSellOrder(provider, exchange[0], tokens, {amount, price} , dispatch)

        console.log("Sell")
        setAmount(0)
        setPrice(0)

    }

    return(
        <div className="component exchange__orders">
            <div className="component__header flex-between">
                {/* {isBuy ? (<h2>Buy Order</h2>) : <h2>Sell Order</h2>} */}
                <h2>New Order</h2>
                
                <div className="tabs">
                    <button className="tab tab--active" onClick={tabHandler} ref={buyRef}>Buy</button>
                    <button className="tab" onClick={tabHandler} ref={sellRef}>Sell</button>
                </div>
            </div>
            
            <form onSubmit={isBuy ? buyHandler : sellHandler}>
                {isBuy ? (<label> Buy Amount</label>) : <label>Sell Amount</label>}
                <input type='text' id="amount" placeholder="0.0000" value={amount === 0 ? "" : amount} onChange={(e) => setAmount(e.target.value)} />
                {isBuy ? (<label> Buy Price</label>) : <label>Sell Price</label>}
                <input type='text' id="price"  placeholder="0.0000" value={price === 0 ? "" : price}onChange={(e) => setPrice(e.target.value) }/>
                <button className="button button--filled" type='submit'>
                    {isBuy ? <span> Buy Order</span> : <span> Sell Order</span> }
                </button>
            </form>



        </div>
        
    );
}

export default Order;