
import logo from "../assets/logo.png";
import Blockies from "react-blockies";

import eth from "../assets/eth.svg";

import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { loadAccount } from "../store/interactions";

import config from "../config.json"

import {useState} from "react";

const NavBar = () => {

    
    const account = useSelector(state => state.provider.account)
    const balance = useSelector(state => state.provider.balance)
    const provider = useSelector(state => state.provider.connection)
    const chainId = useSelector(state => state.provider.chainId)

    const dispatch = useDispatch()

    const [chainID, setChainID] = useState(0)

    console.log("my ChainID balue" + chainID)

    const connectHandler = async() => {

        await loadAccount(provider, dispatch)        
        
    }

    const networkHandler = async(event) =>{

        console.log(event.target.value)
        window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{chainId: event.target.value}]
        })
    
    }
    
    return(
        <div className = 'exchange__header grid'>

            <div className = "exchange__header--brand flex">
                <img src={logo} className="logo" alt="xcr logo"></img>
                <h1>xCzsr Token Exchange</h1>

            </div>

            <div className = "exchange__header--networks flex">
                <img src={eth} alt="Eth Logo" className="Eth Logo"/>

                {chainId && (

                    <select name="networks" id="networks" value={config[chainId] ? `0x${chainId.toString(16)}` : "0" } onChange={networkHandler}>
                        <option value="0" disabled>Select Network</option>
                        <option value="0x7A69"> Localhost</option>
                        <option value="0x5">Goerli</option>
                    </select>
                )}
            </div>
            
            <div className = "exchange__header--account flex">

                { balance ? <p> <small> My balance </small> { parseFloat(balance).toFixed(2) }</p> : <p>Balance Not loaded</p>}
                { account ?   <a href={ config[chainId] ? `${config[chainId].explorerURL}/address/${account}`: "#"} 
                                 target="_blank"
                                 rel="noreferrer"> 
                                 {account.slice(0,6) + "..." + account.slice(38, 42)} 
                                    <Blockies account={account} 
                                              size={10} 
                                              scale={3}
                                              color="#2187D0"
                                              bgColor="#F1F3F9"
                                              seed="random"                                              
                                              spotColor="#767D92"
                                              className="identicon"> 
                                    </Blockies> 
                              </a> : 
                              <button className="button" onClick={ async() =>{  connectHandler() }
                              }>Connect</button>
                }
            </div>
        </div>
    )
  
}

export default NavBar;