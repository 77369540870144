import { useSelector } from "react-redux";
import { fillOrderSelector } from "../store/selector";

import Banner from "./Banner";

import sort  from "../assets/sort.svg"

const Trades = () => {

    const filledOrders = useSelector(fillOrderSelector)
    const symbols = useSelector(state => state.tokens.symbols)


    return(
        <div className="component exchange__trades">
            <div className="component__header flex-between">
                <h2>Trades</h2>
            </div>

            {!filledOrders || filledOrders.length === 0 ? 


            <Banner text={"No Transaction"}></Banner>

            :

                <table>
                    <thread>
                        <tr>
                            <th>Time <img src={sort} alt = "Sort"/></th>
                            <th>{symbols && symbols[0]}<img src={sort} alt = "Sort"/></th>
                            <th>{symbols && symbols[0]}/{symbols && symbols[1]}<img src={sort} alt = "Sort"/></th>
                        </tr>
                    </thread>
                    <tbody>

                        { filledOrders && filledOrders.map((order, index) => {
                            return(
                                <tr key={index}>
                                    <td>{order.formattedTimeStamp}</td>
                                    <td style={{color : `${order.tokenPriceClass}`}}>{order.token0Amount}</td>
                                    <td>{order.tokenPrice}</td>
                                </tr>
                            )
                        })}
                
                    </tbody>
                </table>
        
            }
        </div>
    );

}

export default Trades;