import config from "../config.json"
import { useDispatch, useSelector } from "react-redux"
import { loadTokens } from "../store/interactions"

const Markets =()=> {


    const chainId  = useSelector(state => state.provider.chainId)
    const provider = useSelector(state => state.provider.connection)

    const dispatch = useDispatch()

    const marketHandler = async(event) => {
        
        console.log("inside event")
        console.log(event.target.value.split(",")[1])
        await loadTokens(provider, (event.target.value).split(',') , dispatch)

    }
    return(
        <div className="component exchange__markets">
            <div className="components__headers">
                <h2>Select Markets</h2>
            </div>

            {chainId && config[chainId] ? (
                <select name="markets" id="markets" onChange={marketHandler}>
                    <option value={`${config[chainId]["xCzsr"].address},${config[chainId]["mEth"].address}`} > XCZSR / mETH</option>
                    <option value={`${config[chainId]["mDai"].address},${config[chainId]["mEth"].address}`} > mDai / mETH</option>
                    <option value={`${config[chainId]["mEth"].address},${config[chainId]["xCzsr"].address}`} > mETH / XCZSR </option>
                </select>

            ) : (
                <div> 
                    <p>No ChainId</p>
                </div>
            )}
            <hr/>
        </div>
    )
}

export default Markets;