import { ethers, Signer } from "ethers";
import { exchange, tokens } from "./reducers";

const TOKEN_ABI_JSON = require("../abis/Token.json");
const TOKEN_ABI = TOKEN_ABI_JSON["abi"]

const EXCHANGE_ABI_JSON = require("../abis/Exchange.json");
const EXCHANGE_ABI = EXCHANGE_ABI_JSON["abi"]

export const loadProvider = (dispatch) =>{

    const connection = new ethers.providers.Web3Provider(window.ethereum)
    console.log("Connection Made")
    dispatch({ type: "PROVIDER_LOADED", connection: connection})
    return connection

}

export const loadNetwork = async(provider, dispatch)=> {

    const {chainId} = await provider.getNetwork()
    dispatch({ type: "NETWORK_LOADED", chainId})
    // console.log("Provider network: ", chainId)
    return chainId
   
}

export const loadAccount = async(provider, dispatch) => {
    const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
    
    const account = ethers.utils.getAddress(accounts[0])
    dispatch({type: "ACCOUNT_LOADED", account})
    
    let balance = await provider.getBalance(account)
    balance = ethers.utils.formatEther(balance)
    dispatch({type: "ETHERS_BALANCE_LOADED", balance})

    return account 
}

export const loadTokens = async(provider, addresss, dispatch) =>{

    console.log("size of address", addresss)
    for(let i = 0; i < addresss.length; i++)
    {
        let token, symbol
        token = new ethers.Contract(addresss[i], TOKEN_ABI, provider)
        symbol = await token.symbol()
        let TOKEN_DISPATCH = "TOKEN_LOADED_"+ (i+1).toString()
        console.log(TOKEN_DISPATCH)
    
        dispatch({type: TOKEN_DISPATCH, token, symbol})
    }

}

export const loadToken = async(provider, address, dispatch) =>{

    let token, symbol
    token = new ethers.Contract(address, TOKEN_ABI, provider)
    symbol = await token.symbol()

    dispatch({type: "TOKEN_LOADED", token, symbol})

}

export const loadExchange = async(provider, address, dispatch) => {

    const exchange = new ethers.Contract(address, EXCHANGE_ABI, provider);
    dispatch({ type: "EXCHANGE_LOADED", exchange })

    return exchange

}

export const subscribesToEvents = (exchange, dispatch) =>{


    exchange.on("Deposit", (token, user, amount, balance, event) =>{
        console.log("Deposit SUB")
        
        dispatch({type: "TRANSFER_SUCCESS", event })

    })

    exchange.on("Withdraw", (token, user, amount, balance, event) =>{
        console.log("Withdraw SUB")
        
        dispatch({type: "TRANSFER_SUCCESS", event })

    })

    exchange.on("Order", (id, user, tokenGet, amountGet, tokenGive, amountGive, timestamp, event) =>{
        const order = event.args
        dispatch({type: "NEW_ORDER_SUCCESS", order, event })

    })

    exchange.on("Cancel", (id, user, tokenGet, amountGet, tokenGive, amountGive, timestamp, event) =>{
        const order = event.args
        dispatch({type: "ORDER_CANCEL_SUCCESS", order, event })

    })

    exchange.on("Trade", (id, user, tokenGet, amountGet, tokenGive, amountGive, creator, timestamp, event) =>{
        const order = event.args
        dispatch({type: "ORDER_FILL_SUCCESS", order, event })

    })
}



export const LoadBalance = async(exchange, tokens, account, dispatch) =>{
     
    let balance = ethers.utils.formatUnits(await tokens[0].balanceOf(account), 18)
    dispatch({type: "TOKEN_1_BALANCE_LOADED", balance})
    console.log("token one loaded")

    balance = ethers.utils.formatUnits(await tokens[1].balanceOf(account), 18)
    dispatch({type: "TOKEN_2_BALANCE_LOADED", balance})
    
    balance = ethers.utils.formatUnits(await exchange[0].balanceOf(tokens[0].address, account), 18)
    dispatch({type: "EXCHNAGE_TOKEN_1_BALANCE_LOADED", balance})

    balance = ethers.utils.formatUnits(await exchange[0].balanceOf(tokens[1].address, account), 18)
    // console.log("Balace of token 2 exchange", balance)
    dispatch({type: "EXCHNAGE_TOKEN_2_BALANCE_LOADED", balance})



}

export const loadAllOrders = async(provider, exchange, dispatch) => {
    const block = await provider.getBlockNumber()


    const cancelStream = await exchange.queryFilter("Cancel", 0, block)
    const cancelledOrders = cancelStream.map(event => event.args)

    dispatch({type: "CANCEL_ORDERS_LOADED", cancelledOrders})

    const tradeStream = await exchange.queryFilter("Trade", 0, block)
    const filledOrders = tradeStream.map(event => event.args)
    console.log("filledOrders")
    console.log(filledOrders)

    dispatch({type: "FILLED_ORDERS_LOADED", filledOrders})

    const orderStream = await exchange.queryFilter("Order", 0, block)
    const allOrders = orderStream.map(event => event.args)

    // console.log("All Orders")
    // console.log(allOrders)

    dispatch({type: "ALL_ORDERS_LOADED", allOrders})
}

export const transferTokens = async(provider, exchange, transferType, token, amount, dispatch) => {
    
    let transaction
    let results

    dispatch({type: "TRANSFER_REQUEST"})
    
    try{

        const signer = await provider.getSigner()
        const amountToTransfer = ethers.utils.parseUnits(amount.toString(), 18)
        
        if(transferType === "Deposit"){

            transaction = await token.connect(signer).approve(exchange.address, amountToTransfer)
            await transaction.wait()
            transaction = await exchange.connect(signer).depositToken(token.address, amountToTransfer)
            results = await transaction.wait()
            console.log("inside Deposit")


        }
        else if (transferType === 'Withdraw'){

            transaction = await exchange.connect(signer).withdrawToken(token.address, amountToTransfer)
            results = await transaction.wait()
            console.log("inside withdraw")
        }
        else{
            console.log("Wrong info")
        }
        
        // console.log("Results")
        // console.log(results)

    } catch(error){
        // console.error(error)
        dispatch({type: "TRANSFER_FAIL"})

    }

}

export const makeBuyOrder = async(provider, exchange, tokens, orders, dispatch) => {

    let transaction
    const tokenGet  = tokens[0].address
    const amountGet = ethers.utils.parseUnits(orders.amount, 18)

    const tokenGive = tokens[1].address
    const amountGive = ethers.utils.parseUnits((orders.amount * orders.price).toString(), 18)

    dispatch({type: "NEW_ORDER_REQUEST"})

    try{
        const signer = await provider.getSigner()
        transaction = await exchange.connect(signer).makeOrder(tokenGet, amountGet, tokenGive, amountGive)    
        await transaction.wait()
    }catch(error){
        console.log(error)
        dispatch({type: "NEW_ORDER_FAIL"})
    }
    


}

export const makeSellOrder = async(provider, exchange, tokens, orders, dispatch) => {

    let transaction
    const tokenGet  = tokens[1].address
    const amountGet = ethers.utils.parseUnits((orders.amount * orders.price).toString(), 18)

    const tokenGive = tokens[0].address
    const amountGive = ethers.utils.parseUnits(orders.amount, 18)

    dispatch({type: "NEW_ORDER_REQUEST"})

    try{
        const signer = await provider.getSigner()
        transaction = await exchange.connect(signer).makeOrder(tokenGet, amountGet, tokenGive, amountGive)    
        await transaction.wait()
    }catch(error){
        console.log(error)
        dispatch({type: "NEW_ORDER_FAIL"})
    }
    


}

export const cancelOrder = async(provider, exchange, order, dispatch) => {

    dispatch({type : "ORDER_CANCEL_REQUEST"})
    console.log("Order ID")
    console.log(order.id)

    try {
        const signer = await provider.getSigner()
        console.log("Signer")
        console.log(signer)

        const transaction = await exchange.connect(signer).cancelOrder(order.id)
        await transaction.wait()

        console.log("transaction")
        console.log(transaction)
    } catch(error) {
        console.log("ERROR")
        console.log(error)
        dispatch({ type: "ORDER_CANCEL_FAIL"})
    }    
}

export const fillOrder = async(provider, exchange, order, dispatch) => {
    dispatch({type: "ORDER_FILL_REQUEST"})

    try{
        const signer = await provider.getSigner()
        const transaction = await exchange.connect(signer).fillOrder(order.id)
        await transaction.wait()   
    } catch(error){
        dispatch({type: "ORDER_FILL_FAIL"})
    }
}


