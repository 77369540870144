import { createSelector } from "reselect";
import { get, groupBy, reject, maxBy, minBy} from "lodash";
import { ethers } from "ethers";
import  moment  from "moment";


let GREEN = '#25CE8F'
let RED   = '#F45353'

const tokens = state => get(state, "tokens.contracts")
const account = state => get(state, "provider.account")
const allOrders = state => get(state, "exchange.allOrders.data", [])
const cancelledOrders = state => get(state, "exchange.cancelledOrders.data", [])
const filledOrders = state => get(state, "exchange.filledOrders.data", [])

const events = state => get(state, "exchange.events")

const openOrders = state => {

    const all = allOrders(state)
    const filled = filledOrders(state)
    const cancelled = cancelledOrders(state)
    const openOrders = reject(all, (order) => {

    const orderFilled = filled.some((o) => o.id.toString() === order.id.toString())
    const orderCancelled = cancelled.some((o) => o.id.toString() === order.id.toString())

        return(orderFilled || orderCancelled)
    })

    console.log("Open Orders")
    console.log(openOrders)

    return openOrders

}
const decorateOrderBookOrders = (orders, tokens) => {
    return(
        orders.map((order) => {
            order = decorateOrder(order, tokens)
            order = decorateOrderBookOrder(order, tokens)
            return order
        })
    )
}
const decorateOrder = (order, tokens) => {

    
   let token0Amount, token1Amount
   if(order.tokenGive === tokens[1].address){
    token0Amount = order.amountGive
    token1Amount = order.amountGet
   }
   else{
    token0Amount = order.amountGet
    token1Amount = order.amountGive
   }

   let tokenPrice = (token1Amount / token0Amount)

    return(
        {...order,
        "token0Amount": ethers.utils.formatUnits(token0Amount,18),
        "token1Amount": ethers.utils.formatUnits(token1Amount,18), 
        "tokenPrice": tokenPrice,
        "formattedTimeStamp" : moment.unix(order.timeStamp).format("h:mm:ssa d MMM D")
        })

}
const decorateOrderBookOrder = (order, tokens) => {

    const orderType = order.tokenGive === tokens[1].address ? "buy" : "sell"

    return({
        ...order, 
        "orderType": orderType,
        "orderTypeClass" : (orderType === "buy" ? GREEN : RED),
        "OrderFillAction": (orderType === "buy" ? "sell" : "buy"),
    })

}

const decorateMyOpenOrders = (orders, tokens) => {

    return(
        orders.map((order) => {
            order = decorateOrder(order, tokens)
            order = decorateMyOpenOrder(order, tokens)
            return(order)
        })
    )

}
const decorateMyOpenOrder = (order, tokens) => {

    let orderType = order.tokenGive === tokens[1].address ? "buy" : "sell"
    return(
        {
            ...order,
            orderType: (orderType === 'buy' ? GREEN : RED) 
        }
    )

}

export const myOpenOrdersSelector = createSelector(account, tokens, openOrders, (account, tokens, orders) => {

    if(!tokens[0] || !tokens[1]){return}

    orders = orders.filter((o => o.user === account))
    orders.filter((o) => o.tokenGet === tokens[0].address || o.tokenGet === tokens[1].address)
    orders.filter((o) => o.tokenGive === tokens[0].address || o.tokenGive === tokens[1].address)

    orders = decorateMyOpenOrders(orders, tokens)

    orders = orders.sort((a,b) => b.timeStamp - a.timeStamp)

    return orders
    

})

export const orderBookSelector = createSelector(openOrders, tokens, (orders, tokens) => {


    if(!tokens[0] || !tokens[1]){return}

    orders = orders.filter((o) => o.tokenGet === tokens[0].address || o.tokenGet === tokens[1].address)
    orders = orders.filter((o) => o.tokenGive === tokens[0].address || o.tokenGive === tokens[1].address)

    // console.log("Filtered Orders")
    // console.log(orders)

    orders = decorateOrderBookOrders(orders, tokens)

    orders = groupBy(orders,"orderType" )

    const buyOrders = get(orders, "buy", [])
    
    const sellOrders = get(orders, "sell", [])


    orders = {
        ...orders, 
        buyOrders: buyOrders.sort((a,b) => b.tokenPrice - a.tokenPrice)
    }


    orders = {
        ...orders,
        sellOrders: sellOrders.sort((a,b) => b.tokenPrice - a.tokenPrice)
    }

    return orders

})

export const fillOrderSelector = createSelector(filledOrders, tokens, (orders, tokens) => {

    
    if(!tokens[0] || !tokens[1]) {
        console.log("NO TOKENS")    
        return
    }
    else{

        orders = orders.filter((o) => o.tokenGet === tokens[0].address || o.tokenGet === tokens[1].address)
        orders = orders.filter((o) => o.tokenGive === tokens[0].address || o.tokenGive === tokens[1].address)
        

        // Sort orders 

        // apply order colors 

        orders = orders.sort((a,b) => a.timeStamp - b.timeStamp)

        orders = decordateFilledOrders(orders, tokens)

        orders = orders.sort((a,b) => b.timeStamp - a.timeStamp)
        // sort orders by time for UI

        console.log("Selector Orders")
        console.log(orders)
        return orders
    }
    
})

const decordateFilledOrders = (orders, tokens) => {
    
    let previousOrder = orders[0]

    return (
        orders.map((order) => {

            order = decorateOrder(order, tokens)
            order = decorateFilledOrder(order, previousOrder)
            previousOrder = order
            return order
        })
    )
}

const decorateFilledOrder = (order, previousOrder) => {


    return({
            ...order, 
            tokenPriceClass: tokensPriceClass(order.tokenPrice, order.id, previousOrder)

            }
        )
}
const tokensPriceClass = (tokenPrice, orderId, previousOrder) =>
{

    if(previousOrder.id === orderId)
    {
        return GREEN
    }
    if(previousOrder.tokenPrice <= tokenPrice){
        return GREEN
    }
    else{
        return RED
    }
}
export const PriceChartSelector = createSelector(filledOrders, tokens, (orders, tokens) => {

    if(!tokens[0] || !tokens[1]){return}

    if(orders.length === 0){return}

    console.log("b4 orders")
    console.log(orders)

    orders = orders.filter((o) => o.tokenGet === tokens[0].address || o.tokenGet === tokens[1].address)
    orders = orders.filter((o) => o.tokenGive === tokens[0].address || o.tokenGive === tokens[1].address)

    orders = orders.sort( (a, b) =>  a.timeStamp - b.timeStamp  ) 

    orders = orders.map((o) => decorateOrder(o,tokens))

    console.log("orders")
    console.log(orders)

    let secondLastOrder, lastOrder

    lastOrder = orders[orders.length - 1]

    [secondLastOrder , lastOrder ] = orders.slice(orders.length - 2, orders.length)

    const lastPrice = get(lastOrder, "tokenPrice", 0)
    const secondLastPrice = get(secondLastOrder, "tokenPrice", 0)

    return({
        lastPrice, 
        lastPriceChange: (lastPrice >= secondLastPrice ? "+" : "-"),
        series: [{
            data: buildGraphData(orders)
        }]
    })

})

const buildGraphData = (orders) => {

    orders = groupBy(orders, (o) => moment.unix(o.timeStamp).startOf('hour').format())

    const hours = Object.keys(orders)

    const graphData = hours.map((hour => {

    const group = orders[hour]
    const open = group[0]
    const high =  maxBy(group, "tokenPrice")
    const low =  minBy(group, "tokenPrice")
    const close = group[group.length - 1]

    return({
        x: new Date(hour),
        y: [ open.tokenPrice, high.tokenPrice, low.tokenPrice, close.tokenPrice]
        })
    }))

}

export const myFilledOrdersSelector = createSelector(account, tokens, filledOrders, (account, tokens, orders) => {

    if(!tokens[0] || !tokens[1]){return}

    console.log("Filtered my order before 1")
    console.log(orders)
    orders = orders.filter((o) => o.user === account || o.creator === account)

    console.log("Filtered my order before 2")
    console.log(orders)

    orders.filter((o) => o.tokenGet === tokens[0].address || o.tokenGet === tokens[1].address)
    console.log("Filtered my order before 3")
    console.log(orders)

    orders.filter((o) => o.tokenGive === tokens[0].address || o.tokenGive === tokens[1].address)
    orders.sort((a,b) => a.timeStamp - b.timeStamp)

    console.log("Filtered my order before 4")
    console.log(orders)
    
    orders = decorateMyFilledOrders(orders, account, tokens)

    console.log("Filtered my order")
    console.log(orders)
    

    return orders
    

})


const decorateMyFilledOrders = (orders, account, tokens) => {

    console.log("tempO")
    console.log(orders)

    return(
        orders.map((order) => {
            order = decorateOrder(order, tokens)
            order = decorateMyFilledOrder(order, account, tokens)
            return(order)
        })    
    )

}
const decorateMyFilledOrder = (order, account, tokens) => {

    const myOrder = order.creator === account

    let orderType 

    if(myOrder){
        orderType = order.tokenGive === tokens[1].address ? 'buy' : "sell"
    }
    else{
        orderType = order.tokenGive === tokens[1].address ? 'sell' : "buy"
        
    }
    return(
        {
            ...order,
            orderType, 
            orderClass: (orderType === "buy" ? GREEN : RED),
            orderSign: (orderType === "buy" ? "+" : "-")

        }
    )

}


export const myEventsSelector = createSelector(account, events, (account, events) => {
    
    events = events.filter((e) => e.args.user === account)
    console.log("events")
    console.log(events)
    return events

})



