import { useDispatch, useSelector } from "react-redux"

import sort from "../assets/sort.svg"

import { orderBookSelector } from "../store/selector"
import { fillOrder } from "../store/interactions"

const OrderBook = () =>{

    const tokens = useSelector(state => state.tokens)
    const provider = useSelector(state => state.provider.connection)
    const exchange = useSelector(state => state.exchange.contract)    
    const symbols = useSelector(state => state.tokens.symbols)
    const orderBook = useSelector(orderBookSelector)

    const dispatch = useDispatch()

    const filllOrderHandler = (order) => {
        fillOrder(provider, exchange[0], order, dispatch)
    }

    return(
        <div className="component exchange__orderbook">
            <div className="component__header flex-between">
                <h2>Order Book</h2>
            </div>
            <div className="flex">
                {
                    !orderBook || orderBook.sellOrders.length === 0 ? (
                        <p className="flex-center">No Sell Orders</p>

                    ) : 

                    (
                        <table className="exchange__orderbook--sell">
                        <caption>Selling</caption>
                        <thead>
                            <tr>
                                <th>{symbols && symbols[0]} <img src={sort} alt="Sort"/></th>
                                <th>{symbols && symbols[0]} / {symbols && symbols[1] } <img src={sort} alt="Sort"/></th>
                                <th>{symbols && symbols[1]} <img src={sort} alt="Sort"/> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBook && orderBook.sellOrders.map((order, index)=>{
                                return(
                                    <tr key={index} onClick={() => filllOrderHandler(order)}>
                                        <th>{order.token0Amount}</th>
                                        <th style={{color: order.orderTypeClass}}>{order.tokenPrice}</th>
                                        <th>{order.token1Amount}</th>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                        )
                }
                

                <div className="divider"></div>

                { !orderBook || orderBook.buyOrders.length === 0 ?
                    (<p>NO Buy Orders</p>) 
                    :
                    (
                        <table className="exchange__orderbook--buy">
                            <caption>Buying</caption>
                            <thead>
                                <tr>
                                    <th>{symbols && symbols[0]} <img src={sort} alt="Sort"/></th>
                                    <th>{symbols && symbols[0]} / {symbols && symbols[1] } <img src={sort} alt="Sort"/></th>
                                    <th>{symbols && symbols[1]} <img src={sort} alt="Sort"/> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderBook && orderBook.buyOrders.map((order, index)=>{
                                    return(
                                        <tr key={index} onClick={() => filllOrderHandler(order)}>
                                            <th>{order.token0Amount}</th>
                                            <th style={{color: order.orderTypeClass}}>{order.tokenPrice}</th>
                                            <th>{order.token1Amount}</th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )

                }

                
            </div>
        </div>


    );



}

export default OrderBook;